export const incoterms = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "C&F",
    label: "C&F (CARGO AND FREIGHT)",
  },
  {
    value: "CFR",
    label: "CFR (COST AND FREIGHT)",
  },
  {
    value: "CIF",
    label: "CIF (COST INSURANCE AND FREIGHT)",
  },
  {
    value: "CIP",
    label: "CIP (CARRIAGE AND INSURANCE PAID TO)",
  },
  {
    value: "CPT",
    label: "CPT (CARRIAGE PAID TO)",
  },
  {
    value: "DAP",
    label: "DAP (DELIVERED AT PLACE)",
  },
  {
    value: "DDP",
    label: "DDP (DELIVERED DUTY PAID)",
  },
  {
    value: "DPU",
    label: "DPU (DELIVERED TO PLACE UNLOADED)",
  },
  {
    value: "EXW",
    label: "EXW (EX-WORKS)",
  },
  {
    value: "FAS",
    label: "FAS (FREE ALONGSIDE SHIP)",
  },
  {
    value: "FCA",
    label: "FCA (FREE CARRIER)",
  },
  {
    value: "FOB",
    label: "FOB (FREE ON BOARD)",
  },
];

export const companies = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "SGE",
    label: "SGE",
  },
  {
    value: "SGI",
    label: "SGI",
  },
];

export const warehouses = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "CTCEUR",
    label: "CTCEUR",
  },
  {
    value: "CTCINW",
    label: "CTC INWARD TOBACCO INVENTORY",
  },
  {
    value: "CTCWHS",
    label: "CTC INVENTORY (Hungary)",
  },
  {
    value: "SGI01",
    label: "General Warehouse (Singapore)",
  },
  {
    value: "JESGI",
    label: "SGI Inventory in Jebel Ali",
  },
  {
    value: "NLD01",
    label: "Cross Trade Warehouse in Netherlands",
  },
];

export const shippingInstructionTypes = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "B",
    label: "B",
  },
  {
    value: "HD",
    label: "HD",
  },
  {
    value: "HT",
    label: "HT",
  },
  {
    value: "SD",
    label: "SD",
  },
  {
    value: "ST",
    label: "ST",
  },
];

export const shipmentRequestTypes = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "FVTC",
    label: "FVTC",
  },
  {
    value: "FVTW",
    label: "FVTW",
  },
  {
    value: "FVTPS",
    label: "FVTPS",
  },
  {
    value: "FVTPNS",
    label: "FVTPNS",
  },
  {
    value: "FWTC",
    label: "FWTC",
  },
  {
    value: "FWTW",
    label: "FWTW",
  },
  {
    value: "FWTPS",
    label: "FWTPS",
  },
  {
    value: "FWTPNS",
    label: "FWTPNS",
  },
];

export const countries= [
  {
    value: "-",
    label: "-",
  },
  {
    value: "AF",
    label: "Afghanistan",
  },
  {
    value: "AL",
    label: "Albania",
  },
  {
    value: "DZ",
    label: "Algeria",
  },
  {
    value: "AS",
    label: "American Samoa",
  },
  {
    value: "AD",
    label: "Andorra",
  },
  {
    value: "AO",
    label: "Angola",
  },
  {
    value: "AI",
    label: "Anguilla",
  },
  {
    value: "AQ",
    label: "Antarctica",
  },
  {
    value: "AG",
    label: "Antigua and Barbuda",
  },
  {
    value: "AR",
    label: "Argentina",
  },
  {
    value: "AM",
    label: "Armenia",
  },
  {
    value: "AW",
    label: "Aruba",
  },
  {
    value: "AU",
    label: "Australia",
  },
  {
    value: "AT",
    label: "Austria",
  },
  {
    value: "AZ",
    label: "Azerbaijan",
  },
  {
    value: "BS",
    label: "Bahamas",
  },
  {
    value: "BH",
    label: "Bahrain",
  },
  {
    value: "BD",
    label: "Bangladesh",
  },
  {
    value: "BB",
    label: "Barbados",
  },
  {
    value: "BY",
    label: "Belarus",
  },
  {
    value: "BE",
    label: "Belgium",
  },
  {
    value: "BZ",
    label: "Belize",
  },
  {
    value: "BJ",
    label: "Benin",
  },
  {
    value: "BM",
    label: "Bermuda",
  },
  {
    value: "BT",
    label: "Bhutan",
  },
  {
    value: "BO",
    label: "Bolivia (Plurinational State of)",
  },
  {
    value: "BQ",
    label: "Bonaire, Sint Eustatius and Saba",
  },
  {
    value: "BA",
    label: "Bosnia and Herzegovina",
  },
  {
    value: "BW",
    label: "Botswana",
  },
  {
    value: "BV",
    label: "Bouvet Island",
  },
  {
    value: "BR",
    label: "Brazil",
  },
  {
    value: "IO",
    label: "British Indian Ocean Territory",
  },
  {
    value: "BN",
    label: "Brunei Darussalam",
  },
  {
    value: "BG",
    label: "Bulgaria",
  },
  {
    value: "BF",
    label: "Burkina Faso",
  },
  {
    value: "BI",
    label: "Burundi",
  },
  {
    value: "CV",
    label: "Cabo Verde",
  },
  {
    value: "KH",
    label: "Cambodia",
  },
  {
    value: "CM",
    label: "Cameroon",
  },
  {
    value: "CA",
    label: "Canada",
  },
  {
    value: "KY",
    label: "Cayman Islands",
  },
  {
    value: "CF",
    label: "Central African Republic",
  },
  {
    value: "TD",
    label: "Chad",
  },
  {
    value: "CL",
    label: "Chile",
  },
  {
    value: "CN",
    label: "China",
  },
  {
    value: "CX",
    label: "Christmas Island",
  },
  {
    value: "CC",
    label: "Cocos (Keeling) Islands",
  },
  {
    value: "CO",
    label: "Colombia",
  },
  {
    value: "KM",
    label: "Comoros",
  },
  {
    value: "CD",
    label: "Congo (the Democratic Republic of the)",
  },
  {
    value: "CG",
    label: "Congo",
  },
  {
    value: "CK",
    label: "Cook Islands",
  },
  {
    value: "CR",
    label: "Costa Rica",
  },
  {
    value: "HR",
    label: "Croatia",
  },
  {
    value: "CU",
    label: "Cuba",
  },
  {
    value: "CW",
    label: "Curaçao",
  },
  {
    value: "CY",
    label: "Cyprus",
  },
  {
    value: "CZ",
    label: "Czechia",
  },
  {
    value: "CI",
    label: "Côte d'Ivoire",
  },
  {
    value: "DK",
    label: "Denmark",
  },
  {
    value: "DJ",
    label: "Djibouti",
  },
  {
    value: "DM",
    label: "Dominica",
  },
  {
    value: "DO",
    label: "Dominican Republic",
  },
  {
    value: "EC",
    label: "Ecuador",
  },
  {
    value: "EG",
    label: "Egypt",
  },
  {
    value: "SV",
    label: "El Salvador",
  },
  {
    value: "GQ",
    label: "Equatorial Guinea",
  },
  {
    value: "ER",
    label: "Eritrea",
  },
  {
    value: "EE",
    label: "Estonia",
  },
  {
    value: "SZ",
    label: "Eswatini",
  },
  {
    value: "ET",
    label: "Ethiopia",
  },
  {
    value: "FK",
    label: "Falkland Islands [Malvinas]",
  },
  {
    value: "FO",
    label: "Faroe Islands",
  },
  {
    value: "FJ",
    label: "Fiji",
  },
  {
    value: "FI",
    label: "Finland",
  },
  {
    value: "FR",
    label: "France",
  },
  {
    value: "GF",
    label: "French Guiana",
  },
  {
    value: "PF",
    label: "French Polynesia",
  },
  {
    value: "TF",
    label: "French Southern Territories",
  },
  {
    value: "GA",
    label: "Gabon",
  },
  {
    value: "GM",
    label: "Gambia",
  },
  {
    value: "GE",
    label: "Georgia",
  },
  {
    value: "DE",
    label: "Germany",
  },
  {
    value: "GH",
    label: "Ghana",
  },
  {
    value: "GI",
    label: "Gibraltar",
  },
  {
    value: "GR",
    label: "Greece",
  },
  {
    value: "EL",
    label: "Greece - EL",
  },
  {
    value: "GL",
    label: "Greenland",
  },
  {
    value: "GD",
    label: "Grenada",
  },
  {
    value: "GP",
    label: "Guadeloupe",
  },
  {
    value: "GU",
    label: "Guam",
  },
  {
    value: "GT",
    label: "Guatemala",
  },
  {
    value: "GG",
    label: "Guernsey",
  },
  {
    value: "GN",
    label: "Guinea",
  },
  {
    value: "GW",
    label: "Guinea-Bissau",
  },
  {
    value: "GY",
    label: "Guyana",
  },
  {
    value: "HT",
    label: "Haiti",
  },
  {
    value: "HM",
    label: "Heard Island and McDonald Islands",
  },
  {
    value: "VA",
    label: "Holy See",
  },
  {
    value: "HN",
    label: "Honduras",
  },
  {
    value: "HK",
    label: "Hong Kong",
  },
  {
    value: "HU",
    label: "Hungary",
  },
  {
    value: "IS",
    label: "Iceland",
  },
  {
    value: "IN",
    label: "India",
  },
  {
    value: "ID",
    label: "Indonesia",
  },
  {
    value: "IR",
    label: "Iran (Islamic Republic of)",
  },
  {
    value: "IQ",
    label: "Iraq",
  },
  {
    value: "IE",
    label: "Ireland",
  },
  {
    value: "IM",
    label: "Isle of Man",
  },
  {
    value: "IL",
    label: "Israel",
  },
  {
    value: "IT",
    label: "Italy",
  },
  {
    value: "JM",
    label: "Jamaica",
  },
  {
    value: "JP",
    label: "Japan",
  },
  {
    value: "JE",
    label: "Jersey",
  },
  {
    value: "JO",
    label: "Jordan",
  },
  {
    value: "KZ",
    label: "Kazakhstan",
  },
  {
    value: "KE",
    label: "Kenya",
  },
  {
    value: "KI",
    label: "Kiribati",
  },
  {
    value: "KP",
    label: "Korea (the Democratic People's Republic of)",
  },
  {
    value: "KR",
    label: "Korea (the Republic of)",
  },
  {
    value: "KW",
    label: "Kuwait",
  },
  {
    value: "KG",
    label: "Kyrgyzstan",
  },
  {
    value: "LA",
    label: "Lao People's Democratic Republic",
  },
  {
    value: "LV",
    label: "Latvia",
  },
  {
    value: "LB",
    label: "Lebanon",
  },
  {
    value: "LS",
    label: "Lesotho",
  },
  {
    value: "LR",
    label: "Liberia",
  },
  {
    value: "LY",
    label: "Libya",
  },
  {
    value: "LI",
    label: "Liechtenstein",
  },
  {
    value: "LT",
    label: "Lithuania",
  },
  {
    value: "LU",
    label: "Luxembourg",
  },
  {
    value: "MO",
    label: "Macao",
  },
  {
    value: "MG",
    label: "Madagascar",
  },
  {
    value: "MW",
    label: "Malawi",
  },
  {
    value: "MY",
    label: "Malaysia",
  },
  {
    value: "MV",
    label: "Maldives",
  },
  {
    value: "ML",
    label: "Mali",
  },
  {
    value: "MT",
    label: "Malta",
  },
  {
    value: "MH",
    label: "Marshall Islands",
  },
  {
    value: "MQ",
    label: "Martinique",
  },
  {
    value: "MR",
    label: "Mauritania",
  },
  {
    value: "MU",
    label: "Mauritius",
  },
  {
    value: "YT",
    label: "Mayotte",
  },
  {
    value: "MX",
    label: "Mexico",
  },
  {
    value: "FM",
    label: "Micronesia (Federated States of)",
  },
  {
    value: "MD",
    label: "Moldova (the Republic of)",
  },
  {
    value: "MC",
    label: "Monaco",
  },
  {
    value: "MN",
    label: "Mongolia",
  },
  {
    value: "ME",
    label: "Montenegro",
  },
  {
    value: "MS",
    label: "Montserrat",
  },
  {
    value: "MA",
    label: "Morocco",
  },
  {
    value: "MZ",
    label: "Mozambique",
  },
  {
    value: "MM",
    label: "Myanmar",
  },
  {
    value: "NA",
    label: "Namibia",
  },
  {
    value: "NR",
    label: "Nauru",
  },
  {
    value: "NP",
    label: "Nepal",
  },
  {
    value: "NL",
    label: "Netherlands",
  },
  {
    value: "NC",
    label: "New Caledonia",
  },
  {
    value: "NZ",
    label: "New Zealand",
  },
  {
    value: "NI",
    label: "Nicaragua",
  },
  {
    value: "NE",
    label: "Niger",
  },
  {
    value: "NG",
    label: "Nigeria",
  },
  {
    value: "NU",
    label: "Niue",
  },
  {
    value: "NF",
    label: "Norfolk Island",
  },
  {
    value: "MP",
    label: "Northern Mariana Islands",
  },
  {
    value: "NO",
    label: "Norway",
  },
  {
    value: "OM",
    label: "Oman",
  },
  {
    value: "PK",
    label: "Pakistan",
  },
  {
    value: "PW",
    label: "Palau",
  },
  {
    value: "PS",
    label: "Palestine, State of",
  },
  {
    value: "PA",
    label: "Panama",
  },
  {
    value: "PG",
    label: "Papua New Guinea",
  },
  {
    value: "PY",
    label: "Paraguay",
  },
  {
    value: "PE",
    label: "Peru",
  },
  {
    value: "PH",
    label: "Philippines",
  },
  {
    value: "PN",
    label: "Pitcairn",
  },
  {
    value: "PL",
    label: "Poland",
  },
  {
    value: "PT",
    label: "Portugal",
  },
  {
    value: "PR",
    label: "Puerto Rico",
  },
  {
    value: "QA",
    label: "Qatar",
  },
  {
    value: "MK",
    label: "Republic of North Macedonia",
  },
  {
    value: "RO",
    label: "Romania",
  },
  {
    value: "RU",
    label: "Russian Federation",
  },
  {
    value: "RW",
    label: "Rwanda",
  },
  {
    value: "RE",
    label: "Réunion",
  },
  {
    value: "BL",
    label: "Saint Barthélemy",
  },
  {
    value: "SH",
    label: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    value: "KN",
    label: "Saint Kitts and Nevis",
  },
  {
    value: "LC",
    label: "Saint Lucia",
  },
  {
    value: "MF",
    label: "Saint Martin (French part)",
  },
  {
    value: "PM",
    label: "Saint Pierre and Miquelon",
  },
  {
    value: "VC",
    label: "Saint Vincent and the Grenadines",
  },
  {
    value: "WS",
    label: "Samoa",
  },
  {
    value: "SM",
    label: "San Marino",
  },
  {
    value: "ST",
    label: "Sao Tome and Principe",
  },
  {
    value: "SA",
    label: "Saudi Arabia",
  },
  {
    value: "SN",
    label: "Senegal",
  },
  {
    value: "RS",
    label: "Serbia",
  },
  {
    value: "SC",
    label: "Seychelles",
  },
  {
    value: "SL",
    label: "Sierra Leone",
  },
  {
    value: "SG",
    label: "Singapore",
  },
  {
    value: "SX",
    label: "Sint Maarten (Dutch part)",
  },
  {
    value: "SK",
    label: "Slovakia",
  },
  {
    value: "SI",
    label: "Slovenia",
  },
  {
    value: "SB",
    label: "Solomon Islands",
  },
  {
    value: "SO",
    label: "Somalia",
  },
  {
    value: "ZA",
    label: "South Africa",
  },
  {
    value: "GS",
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    value: "SS",
    label: "South Sudan",
  },
  {
    value: "ES",
    label: "Spain",
  },
  {
    value: "LK",
    label: "Sri Lanka",
  },
  {
    value: "SD",
    label: "Sudan",
  },
  {
    value: "SR",
    label: "Suriname",
  },
  {
    value: "SJ",
    label: "Svalbard and Jan Mayen",
  },
  {
    value: "SE",
    label: "Sweden",
  },
  {
    value: "CH",
    label: "Switzerland",
  },
  {
    value: "SY",
    label: "Syrian Arab Republic",
  },
  {
    value: "TW",
    label: "Taiwan (Province of China)",
  },
  {
    value: "TJ",
    label: "Tajikistan",
  },
  {
    value: "TZ",
    label: "Tanzania, United Republic of",
  },
  {
    value: "TH",
    label: "Thailand",
  },
  {
    value: "TL",
    label: "Timor-Leste",
  },
  {
    value: "TG",
    label: "Togo",
  },
  {
    value: "TK",
    label: "Tokelau",
  },
  {
    value: "TO",
    label: "Tonga",
  },
  {
    value: "TT",
    label: "Trinidad and Tobago",
  },
  {
    value: "TN",
    label: "Tunisia",
  },
  {
    value: "TR",
    label: "Turkey",
  },
  {
    value: "TM",
    label: "Turkmenistan",
  },
  {
    value: "TC",
    label: "Turks and Caicos Islands",
  },
  {
    value: "TV",
    label: "Tuvalu",
  },
  {
    value: "UG",
    label: "Uganda",
  },
  {
    value: "UA",
    label: "Ukraine",
  },
  {
    value: "AE",
    label: "United Arab Emirates",
  },
  {
    value: "GB",
    label: "United Kingdom of Great Britain and Northern Ireland",
  },
  {
    value: "UM",
    label: "United States Minor Outlying Islands",
  },
  {
    value: "US",
    label: "United States of America",
  },
  {
    value: "UY",
    label: "Uruguay",
  },
  {
    value: "UZ",
    label: "Uzbekistan",
  },
  {
    value: "VU",
    label: "Vanuatu",
  },
  {
    value: "VE",
    label: "Venezuela (Bolivarian Republic of)",
  },
  {
    value: "VN",
    label: "Viet Nam",
  },
  {
    value: "VG",
    label: "Virgin Islands (British)",
  },
  {
    value: "VI",
    label: "Virgin Islands (U.S.)",
  },
  {
    value: "WF",
    label: "Wallis and Futuna",
  },
  {
    value: "EH",
    label: "Western Sahara",
  },
  {
    value: "YE",
    label: "Yemen",
  },
  {
    value: "ZM",
    label: "Zambia",
  },
  {
    value: "ZW",
    label: "Zimbabwe",
  },
  {
    value: "AX",
    label: "Åland Islands",
  },
];

export const addressTypes = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Bill To",
    label: "Bill To",
  },
  {
    value: "Pay To",
    label: "Pay To",
  },
  {
    value: "Ship To",
    label: "Ship To",
  },
];


export const portsOfLoading = [
  {
    value: "-",
    label: "-",
  },
  {
    value: "Aarhus - Denmark",
    label: "Aarhus - Denmark",
  },
  {
    value: "Aliaga",
    label: "Aliaga",
  },
  {
    value: "Aliağa - Turkey",
    label: "Aliağa - Turkey",
  },
  {
    value: "Ancona - Italy",
    label: "Ancona - Italy",
  },
  {
    value: "Andromeda Antwerp",
    label: "Andromeda Antwerp",
  },
  {
    value: "Antwerp - Belgium",
    label: "Antwerp - Belgium",
  },
  {
    value: "Antwerp/Tabaknatie",
    label: "Antwerp/Tabaknatie",
  },
  {
    value: "Assens - Denmark",
    label: "Assens - Denmark",
  },
  {
    value: "Assisi - Italy",
    label: "Assisi - Italy",
  },
  {
    value: "Asuncion - Paraguay",
    label: "Asuncion - Paraguay",
  },
  {
    value: "Baltimore - USA",
    label: "Baltimore - USA",
  },
  {
    value: "Bangkok",
    label: "Bangkok - Thailand",
  },
  {
    value: "Bastia Umbra - Italy",
    label: "Bastia Umbra - Italy",
  },
  {
    value: "Batam",
    label: "Batam - Indonesia",
  },
  {
    value: "Batman - Turkiye",
    label: "Batman - Turkiye",
  },
  {
    value: "Beira- Mozambique",
    label: "Beira- Mozambique",
  },
  {
    value: "Beirut - Lebanon",
    label: "Beirut - Lebanon",
  },
  {
    value: "Bremen",
    label: "Bremen",
  },
  {
    value: "Bujanovac",
    label: "Bujanovac - Serbia",
  },
  {
    value: "Burgas - Bulgaria",
    label: "Burgas - Bulgaria",
  },
  {
    value: "CMP",
    label: "CMP",
  },
  {
    value: "Chattogram - Bangladesh",
    label: "Chattogram - Bangladesh",
  },
  {
    value: "Chennai-India",
    label: "Chennai-India",
  },
  {
    value: "Chittagong",
    label: "Chittagong - India",
  },
  {
    value: "Chittagong Bangladesh",
    label: "Chittagong - Bangladesh",
  },
  {
    value: "Chongqing-China",
    label: "Chongqing-China",
  },
  {
    value: "Coega",
    label: "Coega",
  },
  {
    value: "Coka",
    label: "Coka - Serbia",
  },
  {
    value: "Dalian - China",
    label: "Dalian - China",
  },
  {
    value: "Dalian&Qingdao",
    label: "Dalian&Qingdao",
  },
  {
    value: "Dar es Salaam",
    label: "Dar es Salaam - Tanzania",
  },
  {
    value: "Dhaka-Bangladesh",
    label: "Dhaka-Bangladesh",
  },
  {
    value: "Durban - South Africa",
    label: "Durban - South Africa",
  },
  {
    value: "Ennore - India",
    label: "Ennore - India",
  },
  {
    value: "Francolise-Italy",
    label: "Francolise-Italy",
  },
  {
    value: "Futog Serbia",
    label: "Futog Serbia",
  },
  {
    value: "HCMC",
    label: "HCMC - Vietnam",
  },
  {
    value: "Haiphong",
    label: "Haiphong - Vietnam",
  },
  {
    value: "Hanoi Airport-Vietnam",
    label: "Hanoi Airport-Vietnam",
  },
  {
    value: "Harare",
    label: "Harare - South Africa",
  },
  {
    value: "Harare Zimbabwe",
    label: "Harare Zimbabwe",
  },
  {
    value: "Heilongjiang",
    label: "Heilongjiang",
  },
  {
    value: "Holstebro",
    label: "Holstebro - Denmark",
  },
  {
    value: "Huangpu - China",
    label: "Huangpu - China",
  },
  {
    value: "Huangpu/Yantian",
    label: "Huangpu/Yantian",
  },
  {
    value: "Imbituba - Brazil",
    label: "Imbituba - Brazil",
  },
  {
    value: "Itajai - Brazil",
    label: "Itajai - Brazil",
  },
  {
    value: "Jakarta - Indonesia",
    label: "Jakarta - Indonesia",
  },
  {
    value: "Jebel Ali - Dubai",
    label: "Jebel Ali - Dubai",
  },
  {
    value: "Jedrzejow ",
    label: "Jedrzejow ",
  },
  {
    value: "Johannesburg",
    label: "Johannesburg",
  },
  {
    value: "Kattupali - India",
    label: "Kattupali - India",
  },
  {
    value: "Kattupali - İzmir",
    label: "Kattupali - İzmir",
  },
  {
    value: "Kavadarcı",
    label: "Kavadarcı - Bulgaria",
  },
  {
    value: "Koper - Slovenia",
    label: "Koper - Slovenia",
  },
  {
    value: "Krishnapatnam - India",
    label: "Krishnapatnam - India",
  },
  {
    value: "Lublin - Poland",
    label: "Lublin - Poland",
  },
  {
    value: "Lukowa - Poland",
    label: "Lukowa - Poland",
  },
  {
    value: "Malang",
    label: "Malang",
  },
  {
    value: "Manila",
    label: "Manila - Philippines",
  },
  {
    value: "Mersin - İzmir",
    label: "Mersin - İzmir",
  },
  {
    value: "Mumbai - India",
    label: "Mumbai - India",
  },
  {
    value: "Navegantes - Brazil",
    label: "Navegantes - Brazil",
  },
  {
    value: "Norfolk",
    label: "Norfolk - USA",
  },
  {
    value: "Nyiregyhaza",
    label: "Nyiregyhaza - Hungary",
  },
  {
    value: "Piraeus - Greece",
    label: "Piraeus - Greece",
  },
  {
    value: "Port Elizabethj",
    label: "Port Elizabeth - South Africa",
  },
  {
    value: "Qingdao - China",
    label: "Qingdao - China",
  },
  {
    value: "Rio Grande",
    label: "Rio Grande - Brazil",
  },
  {
    value: "Rotterdam - The Netherlands",
    label: "Rotterdam - The Netherlands",
  },
  {
    value: "Sarospatak",
    label: "Sarospatak - Hungary",
  },
  {
    value: "Sato",
    label: "Sato",
  },
  {
    value: "Satoraljaujhely",
    label: "Satoraljaujhely - Hungary",
  },
  {
    value: "Semarang - Indonesia",
    label: "Semarang - Indonesia",
  },
  {
    value: "Shangai - China",
    label: "Shangai - China",
  },
  {
    value: "South Brazil Port",
    label: "South Brazil Port",
  },
  {
    value: "Surabaya",
    label: "Surabaya",
  },
  {
    value: "Surabaya - Indonesia",
    label: "Surabaya - Indonesia",
  },
  {
    value: "Szolnok",
    label: "Szolnok - Hungary",
  },
  {
    value: "Thessaloniki",
    label: "Thessaloniki - Greece",
  },
  {
    value: "Trieste",
    label: "Trieste - Italy",
  },
  {
    value: "Vilissingen-Oost, The Netherlands",
    label: "Vilissingen-Oost, The Netherlands",
  },
  {
    value: "Virovitica-Croatia",
    label: "Virovitica-Croatia",
  },
  {
    value: "Wuhan - China",
    label: "Wuhan - China",
  },
  {
    value: "Xanthi - Greece",
    label: "Xanthi - Greece",
  },
  {
    value: "Xiamen",
    label: "Xiamen",
  },
  {
    value: "Xiamen - China",
    label: "Xiamen - China",
  },
  {
    value: "Yangtian",
    label: "Yangtian - China",
  },
  {
    value: "Yibin - China",
    label: "Yibin - China",
  },
  {
    value: "İzmir",
    label: "İzmir - Turkey",
  }
];

export const portsOfDischarge = [
  {
    value: "-",
    label: "-",
  },
  {
    value: "Adana - Turkey",
    label: "Adana - Turkey",
  },
  {
    value: "Ambarlı - İstanbul",
    label: "Ambarlı - İstanbul",
  },
  {
    value: "Antwerp - Belgium",
    label: "Antwerp - Belgium",
  },
  {
    value: "Asuncion - Paraguay",
    label: "Asuncion - Paraguay",
  },
  {
    value: "Bastia Umbra - Italy",
    label: "Bastia Umbra - Italy",
  },
  {
    value: "Batam",
    label: "Batam - Indonesia",
  },
  {
    value: "Belawan - Indonesia",
    label: "Belawan - Indonesia",
  },
  {
    value: "Bremerhaven-Germany",
    label: "Bremerhaven-Germany",
  },
  {
    value: "Budapest - Hungary",
    label: "Budapest - Hungary",
  },
  {
    value: "Cat Lai Port-Vietnam",
    label: "Cat Lai Port-Vietnam",
  },
  {
    value: "Colombo - Sri Lanka",
    label: "Colombo - Sri Lanka",
  },
  {
    value: "Farmville - USA",
    label: "Farmville - USA",
  },
  {
    value: "Fos Sur Mer - France",
    label: "Fos Sur Mer - France",
  },
  {
    value: "Gauteng, South Africa",
    label: "Gauteng, South Africa",
  },
  {
    value: "HCMC",
    label: "HCMC - Vietnam",
  },
  {
    value: "Haiphong - Vietnam",
    label: "Haiphong - Vietnam",
  },
  {
    value: "Haiphong via Jebel Ali",
    label: "Haiphong via Jebel Ali",
  },
  {
    value: "Hamburg-Germany",
    label: "Hamburg-Germany",
  },
  {
    value: "Hanoi Airport - Vietnam",
    label: "Hanoi Airport - Vietnam",
  },
  {
    value: "Harare",
    label: "Harare",
  },
  {
    value: "Harare Zimbabwe",
    label: "Harare Zimbabwe",
  },
  {
    value: "Haydarpaşa - İstanbul",
    label: "Haydarpaşa - İstanbul",
  },
  {
    value: "Ho Chi Minh City Port,Vietnam",
    label: "Ho Chi Minh City Port,Vietnam",
  },
  {
    value: "Ho Chi Minh City, Vietnam",
    label: "Ho Chi Minh City, Vietnam",
  },
  {
    value: "Holstebro - Denmark",
    label: "Holstebro - Denmark",
  },
  {
    value: "ICD Phuoc Long 3, HCMC - Vietnam",
    label: "ICD Phuoc Long 3, HCMC - Vietnam",
  },
  {
    value: "J.Ali & Sarostapak",
    label: "J.Ali & Sarostapak",
  },
  {
    value: "J.Ali & Sarostapak & Thessaloniki",
    label: "J.Ali & Sarostapak & Thessaloniki",
  },
  {
    value: "Jebel Ali - Dubai",
    label: "Jebel Ali - Dubai",
  },
  {
    value: "Johannesburg",
    label: "Johannesburg",
  },
  {
    value: "Kavadarcı",
    label: "Kavadarcı - Bulgaria",
  },
  {
    value: "Keelung, Taiwan",
    label: "Keelung, Taiwan",
  },
  {
    value: "Los Angeles - USA",
    label: "Los Angeles - USA",
  },
  {
    value: "Luleburgaz-Turkey",
    label: "Luleburgaz-Turkey",
  },
  {
    value: "Malang",
    label: "Malang - Indonesia",
  },
  {
    value: "Malang-Indonesia",
    label: "Malang-Indonesia",
  },
  {
    value: "Manila",
    label: "Manila - Philippines",
  },
  {
    value: "Manila North Port",
    label: "Manila North Port",
  },
  {
    value: "Mersin - Turkey",
    label: "Mersin - Turkey",
  },
  {
    value: "Moscow - Russia",
    label: "Moscow - Russia",
  },
  {
    value: "Mumbai - India",
    label: "Mumbai - India",
  },
  {
    value: "Nice Airport - France",
    label: "Nice Airport - France",
  },
  {
    value: "Norfolk - USA",
    label: "Norfolk - USA",
  },
  {
    value: "Novorossiysk/Russian Federation",
    label: "Novorossiysk/Russian Federation",
  },
  {
    value: "Nyíregyháza - Hungary",
    label: "Nyíregyháza - Hungary",
  },
  {
    value: "Phnom Penh - Cambodia",
    label: "Phnom Penh - Cambodia",
  },
  {
    value: "Piraeus - Greece",
    label: "Piraeus - Greece",
  },
  {
    value: "Ras Al Khaimah - UAE",
    label: "Ras Al Khaimah - UAE",
  },
  {
    value: "Rio Grande - Brasil",
    label: "Rio Grande - Brasil",
  },
  {
    value: "Rotterdam - The Netherlansds",
    label: "Rotterdam - The Netherlansds",
  },
  {
    value: "SIHANOUKVILLE PORT , CAMBODIA",
    label: "SIHANOUKVILLE PORT , CAMBODIA",
  },
  {
    value: "Sarospatak - Hungary",
    label: "Sarospatak - Hungary",
  },
  {
    value: "Semarang - Indonesia",
    label: "Semarang - Indonesia",
  },
  {
    value: "Sihanoukville - Cambodia",
    label: "Sihanoukville - Cambodia",
  },
  {
    value: "St. Petersburg - Russia",
    label: "St. Petersburg - Russia",
  },
  {
    value: "Surabaya-Indonesia",
    label: "Surabaya-Indonesia",
  },
  {
    value: "Taichung-Taiwan",
    label: "Taichung-Taiwan",
  },
  {
    value: "Taiwan",
    label: "Taiwan",
  },
  {
    value: "Thessaloniki -Greece",
    label: "Thessaloniki -Greece",
  },
  {
    value: "Varna - Bulgaria",
    label: "Varna - Bulgaria",
  },
  {
    value: "Wilson, NC - USA",
    label: "Wilson, NC - USA",
  },
  {
    value: "Yangpu",
    label: "Yangpu-China",
  },
  {
    value: "Yantian",
    label: "Yantian - China",
  },
  {
    value: "Yibin - China",
    label: "Yibin - China",
  },
  {
    value: "İstanbul -Turkey",
    label: "İstanbul -Turkey",
  },
  {
    value: "İzmir - Turkey",
    label: "İzmir - Turkey",
  },
];


export const samplePurposes = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Offer Sample",
    label: "Offer Sample",
  },
  {
    value: "Redrawn Sample",
    label: "Redrawn Sample",
  },
  {
    value: "Trial Sample",
    label: "Trial Sample",
  },
];

export const freightOfferRemarks = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Free Time",
    label: "Free Time",
  },
  {
    value: "Price",
    label: "Price",
  },
  {
    value: "Reliability",
    label: "Reliability",
  },
  {
    value: "Delivery Time",
    label: "Delivery Time",
  },
];

export const titles = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Sir",
    label: "Sir",
  },
  {
    value: "Madam",
    label: "Madam",
  },
];

export const paymentTerm = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "%60 in advance against proforma +40% against sailing confirmation",
    label: "%60 in advance against proforma +40% against sailing confirmation",
  },
  {
    value: "- Cash Basis -",
    label: "- Cash Basis -",
  },
  {
    value: "10 days after the Transfer of Ownership",
    label: "10 days after the Transfer of Ownership",
  },
  {
    value: "10% advance, 90% 60 Days after B/L",
    label: "10% advance, 90% 60 Days after B/L",
  },
  {
    value: "120 days after B/L date",
    label: "120 days after B/L date",
  },
  {
    value: "1MD - Monthly credit payment of a full month's supply",
    label: "1MD - Monthly credit payment of a full month's supply",
  },
  {
    value: "25% against invoice, 75% 90 days after invoice date",
    label: "25% against invoice, 75% 90 days after invoice date",
  },
  {
    value: "25% CAD,25% 45 Days,25% 90 Days,25% 120 Days after B/L",
    label: "25% CAD,25% 45 Days,25% 90 Days,25% 120 Days after B/L",
  },
  {
    value:
      "2MD - Monthly credit payment of a full month's supply plus an extra calendar month",
    label:
      "2MD - Monthly credit payment of a full month's supply plus an extra calendar month",
  },
  {
    value: "30% advance, 40% against B/L, 30% after arrival and acceptance",
    label: "30% advance, 40% against B/L, 30% after arrival and acceptance",
  },
  {
    value: "30% advance, 70% after shipment confirmation",
    label: "30% advance, 70% after shipment confirmation",
  },
  {
    value: "30% advance, 70% within 30 days after CMR",
    label: "30% advance, 70% within 30 days after CMR",
  },
  {
    value: "35% advance,65% 60 days after B/L",
    label: "35% advance,65% 60 days after B/L",
  },
  {
    value: "40% TT advance 60% after production finish ",
    label: "40% TT advance 60% after production finish ",
  },
  {
    value: "45 days Net after arrival and acceptance of goods”",
    label: "45 days Net after arrival and acceptance of goods”",
  },
  {
    value: "50 % CAD and 50 % 120 days after invoice",
    label: "50 % CAD and 50 % 120 days after invoice",
  },
  {
    value: "50% 60 days after B/L, 50% 90 days after B/L",
    label: "50% 60 days after B/L, 50% 90 days after B/L",
  },
  {
    value: "50% 60 days as of B/L date + 50% 120 days as of B/L date.",
    label: "50% 60 days as of B/L date + 50% 120 days as of B/L date.",
  },
  {
    value: "50% advance, % 50 90 days after B/L",
    label: "50% advance, % 50 90 days after B/L",
  },
  {
    value: "50% advance, 50% CAD",
    label: "50% advance, 50% CAD",
  },
  {
    value: "50% advance,90 days after B/L",
    label: "50% advance,90 days after B/L",
  },
  {
    value: "50% after 30 days and 50% after 60 days from the BL Date",
    label: "50% after 30 days and 50% after 60 days from the BL Date",
  },
  {
    value: "50% after order confirmation+50% after sailing",
    label: "50% after order confirmation+50% after sailing",
  },
  {
    value:
      "50% aganist presentation of Shipping documents + 50% after arrival and inspection.",
    label:
      "50% aganist presentation of Shipping documents + 50% after arrival and inspection.",
  },
  {
    value: "50% CAD, 50 % 30 days of BL",
    label: "50% CAD, 50 % 30 days of BL",
  },
  {
    value: "50% CAD, 50% 60 days as of BL",
    label: "50% CAD, 50% 60 days as of BL",
  },
  {
    value: "50% CAD, 50% 90 days after B/L",
    label: "50% CAD, 50% 90 days after B/L",
  },
  {
    value: "50% CAD, 50% after arrival &inspection",
    label: "50% CAD, 50% after arrival &inspection",
  },
  {
    value: "50% cash in adv + 50% 60 days after B/L date",
    label: "50% cash in adv + 50% 60 days after B/L date",
  },
  {
    value: "50% CIA, 50%  before shipment",
    label: "50% CIA, 50%  before shipment",
  },
  {
    value: "50% within 90 days from BL date; 50% within 120 days from BL date",
    label: "50% within 90 days from BL date; 50% within 120 days from BL date",
  },
  {
    value: "60 % immediately and 40 % within 30 days.",
    label: "60 % immediately and 40 % within 30 days.",
  },
  {
    value: "60 days after B/L Date",
    label: "60 days after B/L Date",
  },
  {
    value: "60 working days after B/L and acceptance",
    label: "60 working days after B/L and acceptance",
  },
  {
    value: "60% CAD + 40% after arrival and acceptance of the goods.",
    label: "60% CAD + 40% after arrival and acceptance of the goods.",
  },
  {
    value: "60% in advance + 40% after 45 days from BL date",
    label: "60% in advance + 40% after 45 days from BL date",
  },
  {
    value: "70%  CAD, 30% 60 days after BL date",
    label: "70%  CAD, 30% 60 days after BL date",
  },
  {
    value: "70%  CAD, 30% 70 days after BL",
    label: "70%  CAD, 30% 70 days after BL",
  },
  {
    value: "70%  CAD, 30% 75 days after BL",
    label: "70%  CAD, 30% 75 days after BL",
  },
  {
    value: "70% advance, 30% CAD",
    label: "70% advance, 30% CAD",
  },
  {
    value: "70% in advance + 30% after 45 days from BL date",
    label: "70% in advance + 30% after 45 days from BL date",
  },
  {
    value: "80% CAD, 20% 60 after BL",
    label: "80% CAD, 20% 60 after BL",
  },
  {
    value: "90 Days After B/L Date (ULT)",
    label: "90 Days After B/L Date (ULT)",
  },
  {
    value: "90% CAD, 10% 60 after BL",
    label: "90% CAD, 10% 60 after BL",
  },
  {
    value: "CAD - Cash Against Documents",
    label: "CAD - Cash Against Documents",
  },
  {
    value: "Cash account - Account conducted on a cash basis, no credit",
    label: "Cash account - Account conducted on a cash basis, no credit",
  },
  {
    value: "CBS - Cash before shipment",
    label: "CBS - Cash before shipment",
  },
  {
    value: "CIA - Cash in advance",
    label: "CIA - Cash in advance",
  },
  {
    value: "CND - Cash next delivery",
    label: "CND - Cash next delivery",
  },
  {
    value: "COD - Cash on delivery",
    label: "COD - Cash on delivery",
  },
  {
    value:
      "Contra - Payment from the customer offset against the value of supplies purchased from the customer",
    label:
      "Contra - Payment from the customer offset against the value of supplies purchased from the customer",
  },
  {
    value: "CWO - Cash with order",
    label: "CWO - Cash with order",
  },
  {
    value: "EOM - End of month",
    label: "EOM - End of month",
  },
  {
    value:
      "Letter of credit - A documentary credit confirmed by a bank, often used for export",
    label:
      "Letter of credit - A documentary credit confirmed by a bank, often used for export",
  },
  {
    value: "Net 10 - Payment ten days after B/L date",
    label: "Net 10 - Payment ten days after B/L date",
  },
  {
    value: "Net 120 - Payment 120 days after invoice date",
    label: "Net 120 - Payment 120 days after invoice date",
  },
  {
    value: "Net 15 - Payment 15 days after invoice date",
    label: "Net 15 - Payment 15 days after invoice date",
  },
  {
    value: "Net 30 - Payment 30 days after B/L date",
    label: "Net 30 - Payment 30 days after B/L date",
  },
  {
    value: "Net 30 - Payment 30 days after invoice date",
    label: "Net 30 - Payment 30 days after invoice date",
  },
  {
    value: "Net 45 - Payment 45 days after B/L date",
    label: "Net 45 - Payment 45 days after B/L date",
  },
  {
    value: "Net 45 - Payment 45 days after invoice date",
    label: "Net 45 - Payment 45 days after invoice date",
  },
  {
    value: "Net 60 - Payment 60 days after B/L date",
    label: "Net 60 - Payment 60 days after B/L date",
  },
  {
    value: "Net 60 - Payment 60 days after invoice date",
    label: "Net 60 - Payment 60 days after invoice date",
  },
  {
    value: "Net 7 - Payment seven days after B/L date",
    label: "Net 7 - Payment seven days after B/L date",
  },
  {
    value: "Net 7 - Payment seven days after invoice date",
    label: "Net 7 - Payment seven days after invoice date",
  },
  {
    value: "Net 75 - Payment 75 days after B/L",
    label: "Net 75 - Payment 75 days after B/L",
  },
  {
    value: "Net 90 - Payment 90 days after B/L date",
    label: "Net 90 - Payment 90 days after B/L date",
  },
  {
    value: "Net 90 - Payment 90 days after invoice date",
    label: "Net 90 - Payment 90 days after invoice date",
  },
  {
    value: "On Account",
    label: "On Account",
  },
  {
    value: "Payment in Advance",
    label: "Payment in Advance",
  },
  {
    value: "Stage payment - Payment of agreed amounts at stage",
    label: "Stage payment - Payment of agreed amounts at stage",
  },
  {
    value: "TBA",
    label: "TBA",
  },
  {
    value: "To be deducted from the next payment due",
    label: "To be deducted from the next payment due",
  },
];

export const status = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Not Approved",
    label: "Not Approved",
  },
];

export const shipmentRequestStatus = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Open",
    label: "Open",
  },
  {
    value: "Closed",
    label: "Closed",
  },
];

export const sampleStatus = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Requested",
    label: "Requested",
  },
  {
    value: "Not Requested",
    label: "Not Requested",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Not Approved",
    label: "Not Approved",
  },
  {
    value: "Under Evaluation",
    label: "Under Evaluation",
  },
];

export const packagingTypes = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Bale",
    label: "Bale",
  },
  {
    value: "C48",
    label: "C48",
  },
  {
    value: "C48 With Polyliner",
    label: "C48 With Polyliner",
  },
  {
    value: "C96",
    label: "C96",
  },
  {
    value: "C99",
    label: "C99",
  },
  {
    value: "Carton 50 KG",
    label: "Carton 50 KG",
  },
  {
    value: "Used C48",
    label: "Used C48",
  },
];

export const origin = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Albania",
    label: "Albania",
  },
  {
    value: "Bangladesh",
    label: "Bangladesh",
  },
  {
    value: "Brazil",
    label: "Brazil",
  },
  {
    value: "Bulgaria",
    label: "Bulgaria",
  },
  {
    value: "China",
    label: "China",
  },
  {
    value: "Croatia",
    label: "Croatia",
  },
  {
    value: "Denmark",
    label: "Denmark",
  },
  {
    value: "Europe",
    label: "Europe",
  },
  {
    value: "Germany",
    label: "Germany",
  },
  {
    value: "Greece",
    label: "Greece",
  },
  {
    value: "Hungary",
    label: "Hungary",
  },
  {
    value: "India",
    label: "India",
  },
  {
    value: "Indonesia",
    label: "Indonesia",
  },
  {
    value: "Italy",
    label: "Italy",
  },
  {
    value: "Lebanon",
    label: "Lebanon",
  },
  {
    value: "Macedonia",
    label: "Macedonia",
  },
  {
    value: "Malawi",
    label: "Malawi",
  },
  {
    value: "Morocco",
    label: "Morocco",
  },
  {
    value: "Mozambique",
    label: "Mozambique",
  },
  {
    value: "Philippines",
    label: "Philippines",
  },
  {
    value: "Poland",
    label: "Poland",
  },
  {
    value: "Serbia",
    label: "Serbia",
  },
  {
    value: "South Africa",
    label: "South Africa",
  },
  {
    value: "Spain",
    label: "Spain",
  },
  {
    value: "Sri Lanka",
    label: "Sri Lanka",
  },
  {
    value: "Tanzania",
    label: "Tanzania",
  },
  {
    value: "Thailand",
    label: "Thailand",
  },
  {
    value: "Turkey",
    label: "Turkey",
  },
  {
    value: "Uganda",
    label: "Uganda",
  },
  {
    value: "USA",
    label: "USA",
  },
  {
    value: "Various",
    label: "Various",
  },
  {
    value: "Vietnam",
    label: "Vietnam",
  },
  {
    value: "Zambia",
    label: "Zambia",
  },
  {
    value: "Zimbabwe",
    label: "Zimbabwe",
  },
];

export const color = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
  {
    value: "C",
    label: "C",
  },
];

export const type = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "ACV",
    label: "ACV",
  },
  {
    value: "Basma",
    label: "Basma",
  },
  {
    value: "Besuki",
    label: "Besuki",
  },
  {
    value: "BUR",
    label: "BUR",
  },
  {
    value: "CGF",
    label: "CGF",
  },
  {
    value: "CRES",
    label: "CRES",
  },
  {
    value: "Cut rag",
    label: "Cut rag",
  },
  {
    value: "DAC",
    label: "DAC",
  },
  {
    value: "Dark Virginia",
    label: "Dark Virginia",
  },
  {
    value: "DFC",
    label: "DFC",
  },
  {
    value: "DIET",
    label: "DIET",
  },
  {
    value: "FCV",
    label: "FCV",
  },
  {
    value: "FCV CRES",
    label: "FCV CRES",
  },
  {
    value: "FCV - MYS",
    label: "FCV - MYS",
  },
  {
    value: "FCV - NLS",
    label: "FCV - NLS",
  },
  {
    value: "FCV - TRAD",
    label: "FCV - TRAD",
  },
  {
    value: "Hantervel",
    label: "Hantervel",
  },
  {
    value: "HDBRG BUR",
    label: "HDBRG BUR",
  },
  {
    value: "Izmir",
    label: "Izmir",
  },
  {
    value: "JATIM",
    label: "JATIM",
  },
  {
    value: "Katerini",
    label: "Katerini",
  },
  {
    value: "Krumovgrad",
    label: "Krumovgrad",
  },
  {
    value: "LAC",
    label: "LAC",
  },
  {
    value: "MADURA",
    label: "MADURA",
  },
  {
    value: "Mysore FC",
    label: "Mysore FC",
  },
  {
    value: "ORI",
    label: "ORI",
  },
  {
    value: "Prilep",
    label: "Prilep",
  },
  {
    value: "Saadi",
    label: "Saadi",
  },
  {
    value: "Samsun",
    label: "Samsun",
  },
  {
    value: "Sun Cured",
    label: "Sun Cured",
  },
  {
    value: "Taro Leaf",
    label: "Taro Leaf",
  },
  {
    value: "Toasted Burley",
    label: "Toasted Burley",
  },
  {
    value: "Yayladag",
    label: "Yayladag",
  },
  {
    value: "Vinakonda Burley",
    label: "Vinakonda Burley",
  },
];

export const leafForm = [
  {
    value: " - ",
    label: "  ",
  },
  {
    value: "CRES",
    label: "CRES",
  },
  {
    value: "CUT",
    label: "CUT",
  },
  {
    value: "CUT RAG",
    label: "CUT RAG",
  },
  {
    value: "DIET",
    label: "DIET",
  },
  {
    value: "FHS",
    label: "FHS",
  },
  {
    value: "FINES 1/8",
    label: "FINES 1/8",
  },
  {
    value: "HS",
    label: "HS",
  },
  {
    value: "in cut",
    label: "in cut",
  },
  {
    value: "Lamina",
    label: "Lamina",
  },
  {
    value: "LL",
    label: "LL",
  },
  {
    value: "LONG STEM",
    label: "LONG STEM",
  },
  {
    value: "Long Stem Low",
    label: "Long Stem Low",
  },
  {
    value: "Long Stem Upper",
    label: "Long Stem Upper",
  },
  {
    value: "Machine Strip",
    label: "Machine Strip",
  },
  {
    value: "Mixed Stem",
    label: "Mixed Stem",
  },
  {
    value: "NO 8 FINES",
    label: "NO 8 FINES",
  },
  {
    value: "Redried Butted Loose Leaf",
    label: "Redried Butted Loose Leaf",
  },
  {
    value: "Redried Hand Strips",
    label: "Redried Hand Strips",
  },
  {
    value: "Redried Threshed Lamina",
    label: "Redried Threshed Lamina",
  },
  {
    value: "Redried Tipped & Threshed Lamina",
    label: "Redried Tipped & Threshed Lamina",
  },
  {
    value: "Redried Loose Leaf",
    label: "Redried Loose Leaf",
  },
  {
    value: "SCP",
    label: "SCP",
  },
  {
    value: "Scrap # 6 & 8",
    label: "Scrap # 6 & 8",
  },
  {
    value: "Short Stems",
    label: "Short Stems",
  },
  {
    value: "Small Lamina",
    label: "Small Lamina",
  },
  {
    value: "STR",
    label: "STR",
  },
  {
    value: "STRIP",
    label: "STRIP",
  },
  {
    value: "Strips",
    label: "Strips",
  },
  {
    value: "T&T",
    label: "T&T",
  },
  {
    value: "Threshed Strips",
    label: "Threshed Strips",
  },
  {
    value: "Tipped & Treshed",
    label: "Tipped & Treshed",
  },
  {
    value: "Treshed Strips",
    label: "Treshed Strips",
  },
  {
    value: "TT",
    label: "TT",
  },
  {
    value: "Whole Leaf Threshed",
    label: "Whole Leaf Threshed",
  },
];

export const storage = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "CHINA",
    label: "CHINA",
  },
  {
    value: "THAILAND-BANGKOK",
    label: "THAILAND-BANGKOK",
  },
  {
    value: "BANGLADESH",
    label: "BANGLADESH",
  },
  {
    value: "BRAZIL",
    label: "BRAZIL",
  },
];

export const approved = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];
export const operationType = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "PURCHASE",
    label: "PURCHASE",
  },
  {
    value: "SALE",
    label: "SALE",
  },
];

export const units = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "KG",
    label: "KG",
  },
  {
    value: "TONS",
    label: "TONS",
  },
];

export const orderTypes = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "CUSTOMER",
    label: "CUSTOMER",
  },
  {
    value: "VENDOR",
    label: "VENDOR",
  },
];

export const currencies = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export const offerStatus = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Available",
    label: "Available",
  },
  {
    value: "Not Available",
    label: "Not Available",
  },
  {
    value: "Confirmed",
    label: "Confirmed",
  },
  {
    value: "First Refusal",
    label: "First Refusal",
  },
  {
    value: "Optioned",
    label: "Optioned",
  },
  {
    value: "Reserved",
    label: "Reserved",
  },
  {
    value: "Closed",
    label: "Closed",
  },
];

export const statusType = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "ACTIVE",
    label: "ACTIVE",
  },
  {
    value: "PASSIVE",
    label: "PASSIVE",
  },
];

export const shippedBy = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Customer",
    label: "Customer",
  },
  {
    value: "SGI",
    label: "SGI",
  },
  {
    value: "Shipper",
    label: "Shipper",
  },
];

export const recordStatus = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "ACTIVE",
    label: "ACTIVE",
  },
  {
    value: "PASSIVE",
    label: "PASSIVE",
  },
];

export const shippingInstructionStatus = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "Open",
    label: "Open",
  },
  {
    value: "Cancelled",
    label: "Cancelled",
  },
  {
    value: "Closed",
    label: "Closed",
  },
  {
    value: "Closed In JA",
    label: "Closed In JA",
  },
];

export const unit = [
  {
    value: " - ",
    label: " - ",
  },
  {
    value: "KG",
    label: "KG",
  },
  {
    value: "TONS",
    label: "TONS",
  },
];